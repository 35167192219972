import { createSlice } from "@reduxjs/toolkit";
import menu from "../../helpers/menu";

const initialState = {
  token: null,
  updadateBalanace: 0,
  bags: [],
  permissions: [],
  menuOpen: []
}

export const sessionSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSession: (state, action) => ({
      ...state,
      ...action.payload
    }),
    setPermissions: (state, action) => ({
      ...state,
      permissions: action.payload
    }),
    setMenuOpen: (state, action) => {
      const item = state.menuOpen.find(item => item === action.payload)
      return {
        ...state,
        menuOpen: item ? state.menuOpen.filter(item => item !== action.payload) : [...state.menuOpen, action.payload]
      }
    },
    cleanSession: () => initialState
  }
})

export const { setSession, cleanSession, setPermissions, setMenuOpen } = sessionSlice.actions

export default sessionSlice.reducer

