import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TableData } from '../../../../components';
import { errorAlert } from '../../../../helpers/alerts';
import { setPanel } from '../../../../redux/slices/route/routeSlice';
import { getPanelRuteo, setPanelPrimaryProvider, setPanelSecondaryProvider } from '../../../../services/roteo/routeService';
import { getProvidersToRuteoV2Service } from '../../../../services/admin/providerService';
import { setLoading } from 'redux/slices/uiSlice';

const TAERouteScreen = () => {
  const dispatch = useDispatch();
  const { panel } = useSelector(store => store.route)
  const [providers, setProviders] = useState([])

  useEffect(() => {
    getProvidersToRuteoV2Service()
      .then(response => setProviders(response))
      .catch(error => errorAlert(error))
      .finally(() => {
        getPanelRuteo()
          .then(response => dispatch(setPanel(response)))
          .catch(error => errorAlert(error));
      });
    // eslint-disable-next-line
  }, []);

  const handlerChangePrimaryProvider = async (idProvider, { _id: idOperator, idRegion }) => {
    dispatch(setLoading(true));
    setPanelPrimaryProvider({ idOperator, idProvider, idRegion })
      .then(response => dispatch(setPanel(response)))
      .catch(error => errorAlert(error))
      .finally(() => dispatch(setLoading(false)));
  }

  const handlerChangeSecondaryProvider = async (idProvider, { _id: idOperator, idRegion }) => {
    dispatch(setLoading(true));
    setPanelSecondaryProvider({ idOperator, idProvider, idRegion })
      .then(response => dispatch(setPanel(response)))
      .catch(error => errorAlert(error))
      .finally(() => dispatch(setLoading(false)));
  }

  const setProviderList = (idOperator, otherProvider) => {
    const result = providers.filter(({ _id, operators }) => (
      operators.some(((id) => id === idOperator)) && _id !== otherProvider
    )).map(item => ({
      id: item._id,
      name: item.container_name
    }))
    return [
      { id: 'null', name: 'Sin asignar', operadoras: [] },
      ...result
    ]
  }

  return (
    <>
      <TableData
        dataRows={panel}
        rowsPerPageOpt={100}
        pagination={false}
        headerButtons={[
        ]}
        dataTitle={[
          "Logo",
          "Operadora",
          "Region",
          "Proveedor Primario",
          "Proveedor Secundario",
        ]}
        formatter={({ _id, fd, name, region, primaryProvider, secondaryProvider }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          region,
          {
            name: "provider_primary",
            type: "select_button",
            label: 'Proveedor',
            list: setProviderList(_id, secondaryProvider?._id),
            value: providers.length == 0 ? null : (primaryProvider?._id || 'null'),
            onChange: handlerChangePrimaryProvider
          },
          {
            name: "provider_secondary",
            type: "select_button",
            label: 'Proveedor',
            list: setProviderList(_id, primaryProvider?._id),
            value: providers.length == 0 ? null : (secondaryProvider?._id || 'null'),
            onChange: handlerChangeSecondaryProvider
          },
        ]}
      />
    </>
  )
}

export default TAERouteScreen