import { ListAlt } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableData } from 'components';
import { getAlertMonitorService } from 'services/admin/alertsService';
import { errorAlert } from 'helpers/alerts';
import { setCurrentMonitor, setMonitor } from 'redux/slices/admin/alertSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const AlertMonitorScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { monitor } = useSelector(store => store.alert);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    getAlertMonitorService().then((response) => {
      dispatch(setMonitor(response.sort((a, b) => a.identification - b.identification).reverse()));
    }).catch(error => errorAlert(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const handlerTxns = (item) => {
    dispatch(setCurrentMonitor(item));
    navigate('transacciones');
  }
  return (
    <TableData
      dataRows={monitor}
      loading={loading}
      headerButtons={[]}
      dataTitle={[
        "Id",
        "Tipo",
        "Mensaje",
        "Instancia",
        "Fecha y hora",
      ]}
      formatter={({ identification, alert, message, providerCredential, createdAt }) => [
        identification,
        alert?.name,
        message,
        providerCredential?.name_connector,
        moment(createdAt).format("DD/MM/YYYY HH:mm:ss"),
      ]}
      actions={[
        {
          label: "Transacciones",
          icon: <ListAlt fontSize="medium" />,
          onClick: (data) => handlerTxns(data)
        },
      ]}
    />
  )
}

export default AlertMonitorScreen