import moment from "moment";
String.prototype.toHex = function() {
  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
      hash = this.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
  }
  var color = '#';
  for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 255;
      color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}
export function formatDateForTable(dataDate) {
  // moment(dataDate).format('h:mm:ss d/MM');
  return moment(dataDate).format('DD/MM/YYYY')
}

export function hasSameValues(current, changed) {
  let change = false;
  for (let i = 0; i < Object.entries(changed).length; i++) {
    let [name, value] = Object.entries(changed)[i];
    if (current[name] !== value) {
      change = true;
      break;
    }
    change = false;
  }
  return change;
}

export function validateEmptyFields(data, { validate, skip_fields = [] }) {
  let disable = true;
  const empty = Object.entries(data).filter(([name, value]) => {
    return skip_fields.includes(name) ? false : (value === "" || value === null || value === undefined);
  });
  disable = empty.length !== 0;
  disable = typeof validate === 'function' ? validate(data) : disable
  return disable;
}

export function removeAccents(string) {
  const accents = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };
  return string.split('').map(lyrics => accents[lyrics] || lyrics).join('').toString();
}

export const currencyFormatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const downloadFile = (data, fileName) => {
  const blob = new Blob([data]);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
}

export function hideEmail(email) {
  if (typeof email !== 'string') {
    return 'Invalid email';
  }

  const atIndex = email.indexOf('@');

  if (atIndex < 0) {
    return email; // No '@' symbol found, so return the original email
  }

  const username = email.slice(0, atIndex);
  const hiddenPart = '*'.repeat(username.length - 3) + email.slice(atIndex - 3);

  return hiddenPart;
}

export function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}