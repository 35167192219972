import moment from "moment";
import React, { useEffect, useState } from "react";
import { BackButton, TableData } from "../../components";
import { errorAlert } from "../../helpers/alerts";
import { getPaymentsService } from "../../services/panelService";
import { useNavigate } from "react-router-dom";

const PanelPaymentsScreen = ({ slim }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getPaymentsService(0, 300)
      .then((response) => setList(response))
      .catch((error) => errorAlert(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {!slim && <BackButton name={'Regresar'} click={() => navigate(-1)} />}
      <TableData
        title={"Abonos"}
        onTitleClick={() => slim && navigate('../abonos')}
        maxHeight="40vh"
        loading={loading}
        autonumber={false}
        pagination={false}
        headerStyle={slim && {
          fontSize: 10,
          padding: .5,
        }}
        dataRows={list.map((item) => ({
          ...item,
          style: {
            fontSize: slim ? 10 : 12,
            padding: 0.5,
          }
        }))}
        dataTitle={[
          "Fecha Hora",
          "Bolsa",
          "Tipo",
          "Importe",
          // "Comercio",
          "Usuario",
        ]}
        formatter={({
          createdAt,
          hosterBagId,
          etagSaldo,
          operacionSaldo,
          hashtag,
          user
        }) => ([
          moment(createdAt).format('DD/MM/YY HH:mm:ss '),
          hosterBagId?.hashtag,
          slim ? etagSaldo.replace('ABONO DE', '') : etagSaldo,
          `$${Number(operacionSaldo || 0).toFixed(2)}`,
          // hashtag,
          user?.nameUser
        ])}
      />
    </>
  );
};

export default PanelPaymentsScreen;
