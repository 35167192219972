import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ErrorCodesProviderForm } from 'components';
import { putProviderCredsErrorCodesService } from 'services/admin/providerService';

function CreateErrorCodesScreen() {
    const navigate = useNavigate();
    const { providerCredSelected, providerErrorCodeToCreate } = useSelector(store => store.providers)

    return (
        <ErrorCodesProviderForm
            title={"Cancelar"}
            defaultValues={{
                hebeExceptionCode: "",
                externalExceptionCode: providerErrorCodeToCreate ? providerErrorCodeToCreate : ""
            }}
            goBack={() => {
                navigate(-1);
            }}
            submit={(data) => {
                data['connectorName'] = providerCredSelected.conciliacion_service;
                console.log(data);
                putProviderCredsErrorCodesService(data).then((result) => {
                    console.log(result);
                    navigate(-1);
                }).catch((e) => {
                    console.log(e);
                    navigate(-1);
                });
            }}
        />
    );
}

export default CreateErrorCodesScreen;