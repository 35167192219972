import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, Grid2, Typography } from "@mui/material";
import { InputSelectFieldGrid, TableData } from "../../../components";
import { getProviderCredBalance, getTodayProviderTransactions } from "../../../services/admin/providerService";
import { setDetailedReportFilters } from "redux/slices/report/reportSlice";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { currencyFormatter } from "helpers/util";
import { Loader } from "rsuite";
import { setTitle } from "redux/slices/uiSlice";

const MonitorProviderTransactionsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const { control, watch } = useForm({ defaultValues: { time: 10 } });
  const [time, setTime] = useState(10)
  const [start, setStart] = useState(false)
  const [balances, setBalances] = useState([])

  useEffect(() => {
    dispatch(setTitle('Monitor de Proveedores'));
    getTodayProviderTransactions().then((response) => {
      setList(response)
      setStart(true)
      getProviderCredBalance(response.map(({ id }) => id)).then((response) => setBalances(response))
    }).finally(() => setLoading(false))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!start) return;
    if (watch('time') === time) {
      setTime(0);
      getTodayProviderTransactions().then(response => {
        setList(response)
        getProviderCredBalance(response.map(({ id }) => id)).then((response) => setBalances(response))
      })
    }
    const interval = setInterval(() => {
      setTime(time + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [time, start]);

  useEffect(() => {
    setTime(0);
  }, [watch('time')]);

  const handlerSelectProvider = (credential) => {
    dispatch(setDetailedReportFilters({
      type: 1,
      date: [new Date(), new Date()],
      credential
    }))
    navigate('/reportes/detallado')
  }

  return (
    <>
      {
        loading ? (
          <Grid2 textAlign={'center'} mt={10}>
            <Loader size="lg" content="Cargando..." vertical />
          </Grid2>
        ) : (
          <>
            <Grid container >
              <Grid item xs={6}>
                <Typography variant='h6' color='primary' textAlign={'left'} mb={1} pb={0}>
                  Se actualizará en {watch('time') - time} segundo{watch('time') - time > 1 ? 's' : ''}
                </Typography>
              </Grid>
              <Grid item container xs={6} justifyContent={"end"}>
                <InputSelectFieldGrid
                  xs={3}
                  control={control}
                  name={'time'}
                  label={'Tiempo de actualización'}
                  data={[
                    { id: 1, name: 1 },
                    { id: 5, name: 5 },
                    { id: 10, name: 10 },
                    { id: 15, name: 15 },
                    { id: 30, name: 30 },
                    { id: 60, name: 60 },

                  ]}
                // onSelect={(value) => onSelect(value, setValue)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              {
                list.map(({ id, name, txns, aprovedPercent, deniedPercent }, key) => {
                  const balance = balances.find(({ id: idBalance }) => idBalance === id)?.balance;
                  return (
                    <Grid item xs={6} md={4} key={key} >
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        textAlign={'center'}
                        mb={0}
                        pb={0}
                        onClick={() => handlerSelectProvider(id)}
                        style={{ cursor: 'pointer' }}
                      >
                        {name}
                      </Typography>
                      <Grid container justifyContent={'center'}>
                        <Typography variant='subtitle2' color='green' textAlign={'center'} mb={0} pb={0}>
                          {aprovedPercent.toFixed(2)}%
                        </Typography> /
                        <Typography variant='subtitle2' color='red' textAlign={'center'} mb={0} pb={0}>
                          {deniedPercent.toFixed(2)}%
                        </Typography>
                      </Grid>
                      {balance?.saldo && <Grid container justifyContent={'center'}>
                        <Typography variant='subtitle2' textAlign={'center'} mb={0} pb={0}>
                          Saldo: {currencyFormatter.format(balance?.saldo)}
                        </Typography>
                      </Grid>}
                      {balance?.disponiblePorcentual && <Grid container justifyContent={'center'}>
                        <Typography variant='subtitle2' textAlign={'center'} mb={0} pb={0}>
                          Disponible Porcentual: {currencyFormatter.format(balance?.disponiblePorcentual)}
                        </Typography>
                      </Grid>}
                      {balance?.disponibleFijo && <Grid container justifyContent={'center'}>
                        <Typography variant='subtitle2' textAlign={'center'} mb={0} pb={0}>
                          Disponible Fijo: {currencyFormatter.format(balance?.disponibleFijo)}
                        </Typography>
                      </Grid>}
                      <TableData
                        loading={loading}
                        pagination={false}
                        autonumber={false}
                        rowsPerPageOpt={10}
                        headerStyle={{
                          fontSize: 10,
                          padding: .5,
                        }}
                        dataRows={txns.map((item) => ({
                          ...item,
                          style: {
                            color: item.ProviderResponseCode != "00" && 'red',
                            fontSize: 10,
                            padding: .5,
                          }
                        }))}
                        dataTitle={[
                          "Hora",
                          "Operadora",
                          "Número",
                          "Producto",
                        ]}
                        formatter={({
                          createdAt,
                          operator,
                          phoneNumber,
                          productId,
                        }) => ([
                          moment(createdAt).format('HH:mm:ss'),
                          operator,
                          phoneNumber,
                          productId,
                        ])}
                      />
                    </Grid>
                  )
                })
              }
            </Grid>
          </>
        )
      }

    </>
  );
};

export default MonitorProviderTransactionsScreen;
