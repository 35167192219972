import RootRoutes from './routes/RootRoutes';
import { title } from './config/theme';
import { useEffect } from 'react';
import Images from 'assets/images';
import { CustomProvider } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { esES } from 'rsuite/esm/locales';

function App() {

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    link.href = Images[title.toLocaleLowerCase()].favicon;
    document.title = title;
  }, []);

  return (
    <div className='bg'>
      <CustomProvider theme="light" locale={esES}>
        <RootRoutes />
      </CustomProvider>
    </div>
  );
}

export default App;
