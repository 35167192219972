import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BackButton, TableData } from 'components';
import { getAlertMonitorTxnsService } from 'services/admin/alertsService';
import { errorAlert } from 'helpers/alerts';
import moment from 'moment';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AlertTxnsMonitorScreen = () => {
  const navigate = useNavigate();
  const { currentMonitor } = useSelector(store => store.alert);
  const [txns, setTxns] = useState([])
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    setLoading(true);
    getAlertMonitorTxnsService(currentMonitor.id).then((response) => {
      setTxns(response)
    }).catch(error => errorAlert(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton
            name={"Alertas"}
            click={() => navigate(-1)}
          />
        </div>
        <div className="container-title">
          <div className="title">Alerta - {currentMonitor.alert.name}</div>
          <div className="subtitle">{currentMonitor.message}</div>
          <div className="subtitle">{moment(currentMonitor.createdAt).format("DD/MM/YYYY HH:mm:ss")}</div>
        </div>
        <div style={{ width: "170px" }}></div>
      </Grid>
      <TableData
        dataRows={txns}
        loading={loading}
        dataTitle={[
          "Fecha Hora",
          "Bolsa",
          "Operadora",
          "Producto",
          "Monto",
          "Número",
          "Código",
          "Estado",
          "Tiempo",
        ]}
        formatter={({
          createdAt,
          hosterBagId,
          operator,
          amount,
          phoneNumber,
          productId,
          ProviderResponseCode,
          ProviderResponseMessage,
          time
        }) => [
            moment(createdAt).format('DD/MM/YYYY HH:mm:ss '),
            hosterBagId?.hashtag,
            operator,
            productId,
            `$ ${Number(amount || 0).toFixed(2)}`,
            phoneNumber,
            ProviderResponseCode,
            ProviderResponseMessage,
            `${time} ms`,
          ]}
      />
    </>

  )
}

export default AlertTxnsMonitorScreen