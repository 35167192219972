import Swal from "sweetalert2";

export function confirmAlert({
  title,
  text,
  html,
  icon = "warning",
  showCancelButton = true,
  showDenyButton = false,
  denyButtonText = 'No',
  denyButtonColor = "#0E73ED",
  confirmButtonColor = "#0E73ED",
  cancelButtonColor = "#ED3726",
  confirmButtonText = "Aceptar",
  cancelButtonText = "Cancelar",
  onConfirm = () => { },
  onDenied = () => { },
  onBackdrop = () => { },
  preConfirm,
  input,
  inputAttributes,
  showLoaderOnConfirm,
  width,
}) {
  Swal.fire({
    title,
    text,
    icon,
    html,
    showCancelButton,
    confirmButtonColor,
    cancelButtonColor,
    confirmButtonText,
    cancelButtonText,
    showDenyButton,
    denyButtonText,
    denyButtonColor,
    preConfirm,
    input,
    inputAttributes,
    showLoaderOnConfirm,
    width,
    allowOutsideClick: () => !Swal.isLoading(),
  }).then(async (result) => {
    console.log(result);
    if (result.isConfirmed)
      onConfirm()
    if (result.isDenied)
      onDenied()
    if (result.isDismissed)
      onBackdrop()
  });
}

export function errorAlert({ message, onClose }) {
  console.error(message);
  Swal.fire('¡Error!', message || 'Lo sentimos ha ocurrido un error, intentalo nuevamente, si el error persiste contacta al administrador.', 'error').then(async (result) => {
    if (onClose)
      onClose()
  });
}