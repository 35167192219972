import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ExceptionForm } from "../../../../components";
import { errorAlert } from "../../../../helpers/alerts";
import { hasSameValues } from "../../../../helpers/util";
import { updateException } from "../../../../redux/slices/route/exceptionSlice";
import { updateExceptionService } from "../../../../services/roteo/exceptionService";

const EditExceptionScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentException } = useSelector((store) => store.exceptions);
  const {
    amount,
    operator,
    region,
    segment,
    denomination,
    commerce,
    primaryProvider,
    secondaryProvider,
  } = currentException;

  const values = {
    amount: amount ? amount : "",
    operator: operator ? operator.id : "",
    region: region ? region.id : "",
    segment: segment ? segment.id : "",
    denomination: denomination ? denomination.map(({ id }) => id) : "",
    commerce: commerce ? commerce.id : "",
    primaryProvider: primaryProvider ? primaryProvider.id : "",
    secondaryProvider: secondaryProvider ? secondaryProvider.id : "",
  };

  const handlerBack = () => {
    navigate(-1);
  };
  const handlerSubmit = async (result) => {
    result.operator = result.operator ? result.operator : null;
    result.region = result.region ? result.region : null;
    result.segment = result.segment ? result.segment : null;
    result.denomination = result.denomination ? result.denomination : null;
    result.commerce = result.commerce ? result.commerce : null;
    result.primaryProvider = result.primaryProvider
      ? result.primaryProvider
      : null;
    result.secondaryProvider = result.secondaryProvider
      ? result.secondaryProvider
      : null;
    result.state = currentException.state;
    if (result.amount === '')
      result.amount = 0;
    try {
      const response = await updateExceptionService(
        currentException.id,
        result
      );
      dispatch(updateException(response));
      navigate(-1);
    } catch (error) {
      errorAlert(error);
    }
  };
  const validate = (data) => !hasSameValues(values, data);
  return (
    <ExceptionForm
      title="Editar Excepción"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  );
};

export default EditExceptionScreen;
