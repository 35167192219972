import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BugReport } from '@mui/icons-material';
import { Modal, Button } from '@mui/material';
import { useSelector } from "react-redux";
import { BackButton, TableData } from "components";
import { getTransactionsService, getLogsTxnService, getTransactionsIdsService } from "services/panelService";
import { setErrorCodesHistoryIdsProvider } from 'redux/slices/admin/providerSlice';
import { setLogsTxn } from "redux/slices/report/reportSlice";
import { validatePermission } from 'helpers/authentication';
import { useNavigate } from "react-router-dom";

const PanelTransactionsScreen = ({ slim = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [stop, setStop] = useState(false)
  const { token } = useSelector(store => store.session)
  const { logs } = useSelector(store => store.reports)
  const { hebeErrorCodesHistoryListIds } = useSelector(store => store.providers)
  const [isModalOpen, setIsModalOpen] = useState(false)

  let timer;

  useEffect(() => {
    if (isModalOpen && logs.length > 0) {
      let logsWin = window.open("about:blank", "logs", "width=640,height=320");
      logs.map(({ uuid, date, message }) => {
        logsWin.document.write(uuid + " " + date + " " + message + "<br/>");
      });
      setIsModalOpen(false);
    }
  }, [isModalOpen, logs]);

  useEffect(() => {
    if (token) {
      setStop(false)
      setLoading(true)
      console.log("hebeErrorCodesHistoryListIds", hebeErrorCodesHistoryListIds);
      if (hebeErrorCodesHistoryListIds && hebeErrorCodesHistoryListIds.length > 0) {
        getTransactionsIdsService(hebeErrorCodesHistoryListIds).then((response) => {
          console.log("hebeErrorCodesHistoryListIds.response", response);
          dispatch(setErrorCodesHistoryIdsProvider(null));
          setList(response);
        }).finally(() => setLoading(false))
      } else {
        getData();
      }
    }
    return () => {
      setStop(true);
      clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    getTransactionsService(0, 300).then((response) => {
      setList(response)
      timer = setTimeout(() => {
        if (!stop)
          getData();
      }, 5000);
    }).finally(() => setLoading(false))
  }

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {!slim && <BackButton name={'Regresar'} click={() => navigate(-1)} />}
      <TableData
        title={"Transacciones"}
        onTitleClick={() => slim && navigate('../transacciones')}
        maxHeight="40vh"
        autonumber={false}
        pagination={!slim}
        loading={loading}
        headerStyle={slim && {
          fontSize: 10,
          padding: .5,
        }}
        dataRows={list.map((item) => ({
          ...item,
          style: {
            color: item.ProviderResponseCode != "00" && 'red',
            fontSize: slim ? 10 : 12,
            padding: 0.5,
          }
        }))}
        dataTitle={[
          "Hora",
          !slim && "Bolsa",
          !slim && "Tipo",
          "Operadora",
          "Producto",
          "Monto",
          !slim && "Número",
          "Código",
          "Estado",
          "Tiempo (ms)",
        ].filter(Boolean)}
        formatter={({
          typeReg,
          createdAt,
          hosterBagId,
          operator,
          amount,
          phoneNumber,
          productId,
          ProviderResponseCode,
          ProviderResponseMessage,
          time
        }) => ([
          moment(createdAt).format('DD/MM/YY HH:mm:ss '),
          !slim && hosterBagId?.hashtag,
          !slim && typeReg,
          typeReg === 'TAE' ? operator : '-',
          productId,
          slim ? amount : `$ ${Number(amount || 0).toFixed(2)}`,
          !slim && (typeReg === 'TAE' ? phoneNumber : '-'),
          ProviderResponseCode || '-',
          ProviderResponseMessage || '-',
          time,
        ].filter(Boolean))}
        actions={[
          !slim && validatePermission('proveedores', 'proveedores', ['agregar']) && {
            label: "Logs",
            icon: <BugReport fontSize="medium" />,
            onClick: async (data) => {
              dispatch(setLogsTxn([]));
              console.log("dsts.report", data);
              const result = await getLogsTxnService(data.id);
              dispatch(setLogsTxn(result));
              setIsModalOpen(true);
            },
          },
        ].filter(Boolean)}
      />
    </>

  );
};

export default PanelTransactionsScreen;
