import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ExceptionForm } from '../../../../components';
import { errorAlert } from '../../../../helpers/alerts';
import { addException } from '../../../../redux/slices/route/exceptionSlice';
import { createExceptionService } from '../../../../services/roteo/exceptionService';

const CreateExceptionScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
 // const dispatch = useDispatch();
  const values = {
    amount:"",
    operator: "",
    region: "",
    segment: "",
    denomination: "",
    commerce: "",
    primaryProvider: "",
    secondaryProvider: ""
  };

  const handlerBack = () => {
    navigate(-1);
  };
  const handlerSubmit = async (result) => {
    result.operator = result.operator? result.operator: null;
    result.region = result.region? result.region: null;
    result.segment = result.segment? result.segment: null;
    result.denomination = result.denomination? result.denomination: null;
    result.commerce = result.commerce? result.commerce: null;
    result.primaryProvider = result.primaryProvider? result.primaryProvider: null;
    result.secondaryProvider = result.secondaryProvider? result.secondaryProvider: null;
    if (result.amount === '')
      delete result.amount;
    result.state = false;
    try {
      const response = await createExceptionService(result);
      dispatch(addException(response));
      navigate(-1);
    } catch (error) {
      console.log(error)
      errorAlert(error);
    }
  };
  return (
    <ExceptionForm
      title="Crear Excepción"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
    />
  )
}

export default CreateExceptionScreen