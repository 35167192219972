import moment from "moment";
import React, { useEffect, useState } from "react";
import { BackButton, TableData } from "../../components";
import { errorAlert } from "../../helpers/alerts";
import { getBinnacleService } from "../../services/panelService";
import { useNavigate } from "react-router-dom";

const PanelBinnacleScreen = ({ slim }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getBinnacleService({ page: 0, limit: 300 })
      .then((response) => setList(response))
      .catch((error) => errorAlert(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {!slim && <BackButton name={'Regresar'} click={() => navigate(-1)} />}
      <TableData
        title={"Bitácora"}
        onTitleClick={() => slim && navigate('../bitacora')}
        maxHeight="40vh"
        autonumber={false}
        pagination={false}
        loading={loading}
        headerStyle={slim && {
          fontSize: 10,
          padding: .5,
        }}
        dataRows={list.map((item) => ({
          ...item,
          style: {
            fontSize: slim ? 10 : 12,
            padding: .5,
          }
        }))}
        dataTitle={[
          "Hora",
          "Tipo",
          "Concepto",
          "Usuario",
        ]}
        formatter={({
          createdAt,
          event,
          description,
          userName
        }) => ([
          moment(createdAt).format('DD/MM/YY HH:mm:ss'),
          event,
          description,
          userName
        ])}
      />
    </>
  );
};

export default PanelBinnacleScreen;
