import { AddCircleOutline } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, TableData } from '../../../../components';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { confirmAlert, errorAlert } from '../../../../helpers/alerts';
import { getExceptionsService, removeExceptionService, updateExceptionService } from '../../../../services/roteo/exceptionService';
import { removeException, setCurrentException, setExceptions, updateException } from '../../../../redux/slices/route/exceptionSlice';
// import { validatePermission } from '../../../../helpers/authentication';

const ExceptionScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { exceptions } = useSelector(store => store.exceptions)

  useEffect(() => {
    (async () => {
      try {
        const result = await getExceptionsService();
        dispatch(setExceptions(result));
      }
      catch (error) {
        errorAlert(error);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handlerClick = () => {
    navigate('crear')
  }

  const handlerEdit = async (row) => {
    dispatch(setCurrentException(row));
    navigate("editar")
  };

  const handlerCheck = async (row, currentValue) => {
    try {
      const value = !currentValue;
      await updateExceptionService(row.id, { state: value });
      dispatch(updateException({ id: row.id, data: { state: value } }))
    } catch (error) {
      errorAlert(error);
    }
  }

  const handlerDelete = (row) => {
    confirmAlert({
      title: 'Estas seguro que deseas eliminar esta excepción?',
      // text: `La excepción '${row.name}' será eliminada.`,
      onConfirm: async () => {
        try {
          await removeExceptionService(row.id);
          dispatch(removeException(row.id));
        } catch (error) {
          errorAlert(error);
        }
      },
    });
  };

  return (
    <TableData
      dataRows={exceptions}
      headerButtons={[
        // validatePermission('permisos', 'permisos', ['agregar']) &&
        <CustomButton
          icon={<AddCircleOutline fontSize="large" />}
          key="addException"
          name={"Excepción"}
          click={handlerClick}
        />,
      ]}
      dataTitle={[
        "Operadora",
        "Región",
        "Segmento",
        "Denominación",
        "Comercio",
        "Proveerdor Primario",
        "Proveerdor Secundario",
        "Monto",
        "Estado",
      ]}
      formatter={({ commerce, denomination, operator, region, segment, primaryProvider, secondaryProvider, amount, state }) => (
        [
          operator?.name,
          region?.nombre,
          segment?.name,
          denomination?.map(({ denomination }) => denomination).join(', '),
          commerce?.username,
          primaryProvider?.name_connector,
          secondaryProvider?.name_connector,
          amount,
          { type: 'boolean', value: state },
        ])}
      onChecked={handlerCheck}
      actions={[
        // validatePermission('permisos', 'permisos', ['editar']) &&
        {
          label: "Editar",
          icon: <EditIcon fontSize="medium" />,
          onClick: (data) => {
            handlerEdit(data);
          },
        },
        // validatePermission('permisos', 'permisos', ['eliminar']) &&
        {
          label: "Eliminar",
          icon: <DeleteForeverIcon fontSize="medium" />,
          onClick: (data) => {
            handlerDelete(data);
          }
        }
      ]}
    />
  )
}

export default ExceptionScreen