import { Button, Grid, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import "./ImageInput.css";
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ImageInput = ({ nameInput, iconImage, imageSrc, onChange }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handlerChange = (e) => {
    const image = e.target.files[0];
    onChange(image);
    setSelectedImage(URL.createObjectURL(image));
  };

  useEffect(() => {
    imageSrc && setSelectedImage(imageSrc && `/v3/file/${imageSrc}/`);
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      item
      xs={8}
      direction="column"
      justifyContent={"center"}
      style={{ width: "100%" }}
      className="container-image-icon"
    >
      <Grid justifyContent={"space-around"} style={{ display: "flex" }}>
        {selectedImage && (
          <img
            alt=""
            className="image-icon"
            // src={URL.createObjectURL(selectedImage)}
            src={selectedImage}
          />
        )}
      </Grid>
      {/* <Grid justifyContent={"space-around"} className="container-complement" style={{ display: "flex" }}>
        {nameInput}
      </Grid> */}
      <Grid justifyContent={"space-around"} style={{ display: "flex" }} mt={2}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Subir {nameInput}
          <VisuallyHiddenInput
            type="file"
            onChange={handlerChange}
          />
        </Button>
        {/* <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          className="container-image-edit-icon"
        >
          <input type="file" name="myImage" onChange={handlerChange} />
          <img alt="" className="image-edit-icon" src={icon.icon.edit} />
        </IconButton> */}
      </Grid>
    </Grid>
  );
};

export default ImageInput;
