import React from 'react';
import './BackButton.css';

import icon from '../assets/images';


const BackButton = ({ name, click }) => {
  return (
    <div className='container-wrap-back_button' onClick={click}>
      <div className='container-back_button'>
        <div className='container-back_button-icon' >
          <img alt='menu' className='back_button-icon' src={icon.icon.chevron_left} />
        </div>
        <div className='container-back_button-name'>
          <h4 className='back_button-name'>{name}</h4>
        </div>

      </div>
    </div>
  )
}

export default BackButton;