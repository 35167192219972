import service from "./service";

export const getTotalTxnsByDayService = () => (
  service('get', `v3/panel/operaciones/mes`)
)
export const getDayTxnsByHour = (data) => (
  service('post', `v3/panel/operaciones/dia`, { data })
)
export const getDayTxnsByOperator = (data) => (
  service('post', `v3/panel/operaciones/operadora`, { data })
)
export const getDayTxnsByState = (data) => (
  service('post', `v3/panel/operaciones/estatus`, { data })
)

export const getTransactionsService = (page, limit) => (
  service('get', `v3/panel/monitor/pagina/${page}/limite/${limit}`)
)

export const getTransactionsIdsService = (ids) => (
  service('post', `v3/panel/monitor/txns`, { data: ids })
)

export const getPaymentsService = (page, limit) => (
  service('get', `v3/panel/operaciones/pagina/${page}/limite/${limit}`)
)

export const getBinnacleService = (data) => (
  service('post', `v3/panel/bitacora`, { data })
)

// Se obtienen los logs de cada txn
export const getLogsTxnService = (id) => (
  service('get', `v3/panel/operaciones/log/${id}`)
)