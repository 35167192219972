import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { DateRangePicker } from 'rsuite';
import { Controller } from 'react-hook-form';
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays } from 'rsuite/esm/internals/utils/date';


const InputDateRange = ({ xs, name, control, label, disabled, rules = {}, required }) => {
  return (
    <>
      <Grid
        item
        xs={xs}
        alignContent="center"
        justifyContent={"center"}
        style={{ width: '100%' }}
      >
        <Controller
          name={name}
          control={control}
          rules={!disabled && {
            ...rules,
            required
          }}
          render={({
            field: { value, onChange },
            fieldState: { error },
          }) => (
            <>
              <DateRangePicker
                value={value}
                onChange={onChange}
                disabled={disabled}
                format="dd/MM/yyyy"
                placeholder={label}
                style={{ width: '100%' }} 
                cleanable={false}
                ranges={[
                  {
                    label: 'Hoy',
                    value: [new Date(), new Date()],
                    placement: 'left'
                  },
                  {
                    label: 'Ayer',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                    placement: 'left'
                  },
                  {
                    label: 'Esta semana',
                    value: [startOfWeek(new Date()), endOfWeek(new Date())],
                    placement: 'left'
                  },
                  {
                    label: 'Este mes',
                    value: [startOfMonth(new Date()), new Date()],
                    placement: 'left'
                  },
                  {
                    label: 'Últimos 7 días',
                    value: [subDays(new Date(), 6), new Date()],
                    placement: 'left'
                  },
                  {
                    label: 'Últimos 30 días',
                    value: [subDays(new Date(), 29), new Date()],
                    placement: 'left'
                  },
                ]}
              />
            </>
          )}
        />
      </Grid>
    </>
  )
}

const CalendarsDialog = ({ open, onClose, onSuccess, onSelect }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Seleccionar rango de fechas</DialogTitle>
      <DialogContent>

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={onSuccess}>Aceptar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default InputDateRange