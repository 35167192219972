import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddCircleOutline, ChevronLeft, Close, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { validatePermission } from 'helpers/authentication';
import { errorAlert, confirmAlert } from 'helpers/alerts';
import { CustomButton, TableData } from "components";
import { setErrorCodesProvider, setErrorCodeSelected } from 'redux/slices/admin/providerSlice';
import { getProviderCredsErrorCodesService, deleteProviderCredsErrorCodesService } from 'services/admin/providerService';

function ErrorCodesHomologadosScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(0);
  const { current, errorCodesProvider } = useSelector(store => store.providers)

  useEffect(() => {
    getProviderCredsErrorCodesService(current.host)
      .then(response => dispatch(setErrorCodesProvider(response)))
      .catch(error => errorAlert(error));
  }, [update]);

  return (
    <TableData
      dataRows={errorCodesProvider}
      headerButtons={[
        validatePermission('proveedores', 'proveedores', ['agregar']) &&
        <CustomButton
          icon={<AddCircleOutline fontSize="large" />}
          key="addProviderCred"
          name={"Nueva Homologación"}
          click={() => navigate("create")}
        />,
      ]}
      dataTitle={[
        "Proveedor",
        "HEBE Code",
        "Proveedor Code"
      ]}
      formatter={({ hebeExceptionCode, externalExceptionCode, connectorName }) => ([
        connectorName,
        hebeExceptionCode,
        externalExceptionCode
      ])}
      actions={[
        validatePermission('proveedores', 'productos', ['editar']) &&
        {
          label: "Editar",
          icon: <Edit fontSize="medium" />,
          onClick: (data) => {
            dispatch(setErrorCodeSelected(data));
            navigate("edit");
          },
        },
        validatePermission('proveedores', 'productos', ['editar']) &&
        {
          label: "Eliminar",
          icon: <Close fontSize="medium" />,
          onClick: ({ sku, id }) => {
            confirmAlert({
              title: "Estás seguro que deseas eliminar este error code?",
              text: `Error Code: ${sku} será eliminada.`,
              onConfirm: async () => {
                deleteProviderCredsErrorCodesService(id)
                  .then(() => setUpdate(update + 1))
                  .catch(error => errorAlert(error));
              }
            })
          },
        },
      ]}
    />
  );
}


export default ErrorCodesHomologadosScreen;