import { Grid, useMediaQuery } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { InputSelectFieldGrid, InputTextFieldGrid } from "../../components";
import FavoritesSales from "../../components/sale/FavoritesSales";
import LastSales from "../../components/sale/LastSales";
import { confirmAlert, errorAlert } from "../../helpers/alerts";
import { setDenominations, setOperators, setSegments, setSelectedProduct, setUpdate } from "../../redux/slices/sale/airTimeSlice";
import { getExtraDenominationByDenominationService } from "../../services/admin/extraDenominationService";
import { doStoreSale, sendTiketByEmail } from "../../services/sale/servipagosService";
import xml from "../../helpers/xml";
import { hideEmail, randomNumber, validateEmptyFields } from "../../helpers/util";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getBagDenominationsService, getBagOperatorService, getBagSegmentService, getBagService } from "../../services/client/bagService";
import Swal from "sweetalert2";
import { setSession } from "../../redux/slices/sessionSlice";

const AirTimeSaleScreen = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const { bags, sale: salepointId, updateBalance } = useSelector(store => store.session);
  const balance_bag = bags.find(bag => bag.type === 'TAE');
  const {
    showDetail,
    operators,
    segments,
    denominations,
  } = useSelector(store => store.airTimeSale);
  const { control, handleSubmit, setValue, watch, reset, setError, setFocus } = useForm();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { operator, segment, denomination } = watch();
  const [bag, setBag] = useState({})

  useEffect(() => {
    getBagService(balance_bag.id).then(({ username, password, storeId }) => {
      setBag({ username, password, storeId })
    }).catch((e) => console.error(e))
    dispatch(setSelectedProduct(null));
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let disable = validateEmptyFields(watch(), {});
    setDisabled(disable);
    // eslint-disable-next-line
  }, [watch()]);

  useEffect(() => {
    getBagOperatorService(balance_bag.id)
      .then((response) => dispatch(setOperators(response.filter((item) => item.commerce).map((item) => ({ ...item, id: item._id })))))
      .catch((error) => errorAlert(error));
    return () => {
      dispatch(setSegments([]));
      dispatch(setDenominations([]));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (operator) {
      const tempOperator = operators.find(({ id }) => operator === id);
      dispatch(setSelectedProduct({ image: tempOperator.fd }));
      getBagSegmentService(balance_bag.id, operator, false)
        .then((result) => {
          dispatch(setSegments(result.map((item) => ({ ...item, id: item._id }))))
          if (result.length === 1)
            setValue('segment', result[0]._id)
        })
        .catch((e) => errorAlert(e))
    }
    // eslint-disable-next-line
  }, [operator]);

  useEffect(() => {
    if (segments.length !== 0 && segment) {
      const tempSegment = segments.find(({ id }) => segment === id);
      dispatch(setSelectedProduct({ image: tempSegment.fd }));
      getBagDenominationsService(balance_bag.id, operator, false, segment)
        .then((result) => dispatch(setDenominations(result.map((item) => ({ ...item, id: item._id })))))
        .catch((e) => errorAlert(e))
    }
    // eslint-disable-next-line
  }, [segment, segments]);

  useEffect(() => {
    if (denominations.length !== 0 && denomination) {
      const product = denominations.find(({ id }) => id === denomination);
      getExtraDenominationByDenominationService(denomination)
        .then((extraDenomination) => dispatch(setSelectedProduct({ ...product, extraDenomination, image: product.idSegment.fd })))
        .catch((e) => errorAlert(e))
    }
    // eslint-disable-next-line
  }, [denomination, denominations]);

  const submit = async ({ denomination, phone, confirmPhone }) => {
    if (phone !== confirmPhone) {
      setError('phone', { message: "Los números telefonicos no coinciden" })
      setError('confirmPhone', { message: "Los números telefonicos no coinciden" })
    }
    setLoading(true)
    try {
      const selected_denomination = denominations.find(({ id }) => id === denomination);
      const selected_segment = segments.find(({ id }) => id === segment);
      const selected_operator = operators.find(({ id }) => id === operator);
      const externalTrace = randomNumber(100000, 999999);
      const sale = {
        username: bag.username,
        password: bag.password,
        storeId: bag.storeId,
        provider: selected_segment.carrierId,
        amount: selected_denomination.denomination,
        number: phone,
        productDetail: selected_segment.code,
        externalTrace,
        salepointId
      }
      let xmlResponse = await doStoreSale(sale);
      xmlResponse = new xml(xmlResponse)
      const error = xmlResponse.getElementValue("phon:ProviderResponseCode");
      if (error !== '00') {
        console.log('result:', xmlResponse);
        const message = xmlResponse.getElementValue("phon:ProviderResponseMessage");
        errorAlert({ message: message || "Error" })
      } else {
        reset({ operator: '', segment: '', denomination: '' });
        dispatch(setSelectedProduct(null));
        dispatch(setSession({ updateBalance: updateBalance + 1 }));

        confirmAlert({
          icon: "success",
          title: "Recarga Éxitosa",
          html: `
          <div>
            <h3>Número de autorización ${xmlResponse.getElementValue("phon:AuthCode")}</h3>
            <div>Teléfono: ${phone}</div>
            <div>Compañia: ${selected_operator.name}</div>
            <div>Tipo: ${selected_segment.name}</div>
            <div>Monto: ${sale.amount}</div>
            <div>Fecha: ${moment().format('DD/MM/YYYY HH:mm:ss')}</div>
          </div>`,
          showCancelButton: true,
          showDenyButton: true,
          cancelButtonText: "Cerrar",
          confirmButtonText: "Descargar comprobante",
          denyButtonText: "Enviar por correo electrónico",
          preConfirm: () => {
            document.body.appendChild(document.createElement('div')).innerHTML = `
          <div id="ticket-print">
            <div class="title">Comprobante de compra</div>
            <div class="text">Número de autorización 5555555555</div>
            <div class="text">Teléfono: 5555555555</div>
            <div class="text">Compañia: TELCEL</div>
            <div class="text">Tipo: Recargas</div>
            <div class="text">Monto: 10</div>
            <div class="text">Fecha: ${moment().format('DD/MM/YYYY HH:mm:ss')}</div>
          </div>
        `;
            return html2canvas(document.querySelector('#ticket-print')).then(
              canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                  format: [80, 100],
                  unit: "mm"
                });
                pdf.addImage(imgData, 'PNG', 10, 20);
                pdf.save('comprobante.pdf');
                document.querySelector('#ticket-print').remove();
              }
            );
          },
          onDenied: () => {
            Swal.fire({
              title: 'Ingresa el correo electrónico',
              input: 'text',
              inputAttributes: {
                autocapitalize: 'off'
              },
              showCancelButton: true,
              confirmButtonText: 'Enviar',
              showLoaderOnConfirm: true,
              cancelButtonColor: "#ED3726",
              preConfirm: (email) => {
                return sendTiketByEmail(email, {
                  auth: xmlResponse.getElementValue("phon:AuthCode"),
                  phone: phone,
                  operator: selected_operator.name,
                  segment: selected_segment.name,
                  amount: sale.amount,
                  date: moment().format('DD/MM/YYYY HH:mm:ss'),
                }).then(() => {
                  confirmAlert({
                    icon: "success",
                    title: "Enviado",
                    text: `Se ha enviado el ticket de la transacción al correo electrónico ${hideEmail(email)}`,
                    showCancelButton: false,
                  })
                }).catch((error) => {
                  Swal.showValidationMessage(error)
                })
              },
              allowOutsideClick: () => !Swal.isLoading()
            })
          }
        })
      }
    } catch (e) {
      console.error(e);
      if (e.code === 501)
        errorAlert({ message: e.message })
      else
        errorAlert({ message: 'Ha ocurrido un error al realizar la recarga, Vuelve a intentarlo y si el error persiste contacta a tu administrador.' })
    } finally {
      setLoading(false)
      dispatch(setUpdate(moment().unix()))
    }
  }

  const handlerSelectFavorite = (product) => {
    setValue('operator', null)
    setTimeout(() => {
      setTimeout(() => {
        setFocus('phone')
      }, 500);
      if (product._id === denomination)
        return
      console.log('product:', product, operators, segments, denominations);
      dispatch(setSegments([]))
      dispatch(setDenominations([]))
      setValue('operator', product.operator._id)
      setValue('segment', product.segment._id)
      setValue('denomination', product._id)
    }, 100);
  }

  return (
    <>
      {/* <Grid container justifyContent={"flex-end"}>
        <Button
          size={isSmallScreen ? 'small' : 'large'}
          sx={{
            fontWeight: "bold",
            backgroundColor: showDetail ? '#2a2a2a' : '#319643'
          }}
          onClick={() => dispatch(setShowDetail(!showDetail))}
          variant="contained"
        >
          {showDetail ? 'Ocultar detalle' : 'Ver Detalle'}
        </Button>
      </Grid> */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          mt={1}
          // justifyContent="center"
          width={showDetail ? "70%" : isSmallScreen ? "60%" : "40%"}
          spacing={3}
        >
          <InputSelectFieldGrid
            name={"operator"}
            xs={showDetail ? 6 : 12}
            control={control}
            required={"Debes seleccionar una operadora"}
            label={"Compañia"}
            data={operators}
            size={isSmallScreen ? 'small' : 'large'}
            formatter={(data) =>
              data?.map(({ id, name }) => ({ id: id, name: name }))
            }
            onSelect={() => {
              setValue('segment', '')
              setValue('denomination', '')
              dispatch(setSelectedProduct(null));
            }}
          />
          <InputSelectFieldGrid
            xs={6}
            control={control}
            name={"segment"}
            required={"Debes seleccionar un segmento"}
            label={"Tipo"}
            data={segments}
            size={isSmallScreen ? 'small' : 'large'}
            disabled={operator == null}
            formatter={(data) =>
              data?.map(({ id, name }) => ({ id: id, name: name }))
            }
            onSelect={() => {
              setValue('denomination', '')
              dispatch(setSelectedProduct(null));
            }}
          />
          <InputSelectFieldGrid
            xs={12}
            control={control}
            name={"denomination"}
            label={"Monto"}
            required={"Debes seleccionar un monto"}
            data={denominations}
            size={isSmallScreen ? 'small' : 'large'}
            disabled={!segment}
            formatter={(data) =>
              data.map((item) => ({ id: item.id, name: item.denomination }))
            }
          />
          <InputTextFieldGrid
            xs={12}
            control={control}
            name={"phone"}
            label={"Número celular"}
            rules={{
              required: { value: true, message: "Debes agregar el número celular" },
              maxLength: { value: 10, message: "El número celular es de máximo 10 dígitos" },
              pattern: { value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, message: "Número celular inválido" },
            }}
            size={isSmallScreen ? 'small' : 'large'}
            inputType={'tel'}
            inputProps={{
              type: 'tel',
              maxLength: 10
            }}
            sx={showDetail ? {} : {
              '& .MuiInputBase-input': {
                fontSize: 70,
                textAlign: 'center'
              }
            }}
          />
          <InputTextFieldGrid
            xs={12}
            name={"confirmPhone"}
            control={control}
            label={"Confirma número celular"}
            rules={{
              required: { value: true, message: "Debes agregar el número celular" },
              maxLength: { value: 10, message: "El número celular es de máximo 10 dígitos" },
              pattern: { value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, message: "Número celular inválido" },
            }}
            size={isSmallScreen ? 'small' : 'large'}
            inputType={'tel'}
            inputProps={{
              type: 'tel',
              maxLength: 10
            }}
            sx={showDetail ? {} : {
              '& .MuiInputBase-input': {
                fontSize: 70,
                textAlign: 'center'
              }
            }}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingButton
            loading={loading}
            size={isSmallScreen ? 'small' : 'large'}
            sx={{
              m: 2,
              paddingX: showDetail ? 5 : 10,
              fontWeight: "bold",
              backgroundColor: "#0E73ED",
              fontSize: showDetail ? 14 : 50
            }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          // fullWidth
          >
            Recargar
          </LoadingButton>
        </Grid>
      </Grid>
      {
        showDetail && (
          <>
            <FavoritesSales onSelect={handlerSelectFavorite} />
            <LastSales />
          </>
        )
      }
    </>
  );
};

export default AirTimeSaleScreen;
