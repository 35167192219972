import { BarChart, PieChart } from '@mui/x-charts'
import { FilterBar } from 'components'
import moment from 'moment'
import React, { useState } from 'react'
import { getDayTxnsByHour, getDayTxnsByOperator, getDayTxnsByState } from 'services/panelService'

const Graficas = () => {
  const [day, setDay] = useState({})
  const [operators, setOperators] = useState({})
  const [states, setStates] = useState({})

  const handlerDaySales = (data) => {
    getDayTxnsByHour(data).then((response) => {
      setDay(response.reduce((acc, { _id, total }) => {
        const date = moment(_id).subtract(6, 'hours').format('HH:mm');
        acc[date] = (acc[date] || 0) + total;
        return acc;
      }, {}))
    });
    getDayTxnsByOperator(data).then((response) => {
      setOperators(response.reduce((acc, { _id, total }) => {
        acc[_id.operator] = (acc[_id.operator] || 0) + total;
        return acc;
      }, {}))
    });
    getDayTxnsByState(data).then((response) => {
      setStates(response.reduce((acc, { _id, total }) => {
        _id = _id ? 'Aprobadas' : 'Denegadas';
        acc[_id] = (acc[_id] || 0) + total;
        return acc;
      }, {}))
    });
  }
  return (
    <>
      <h1></h1>
      <FilterBar
        handlerFilter={handlerDaySales}
        defaultValues={{
          date: moment().format('YYYY-MM-DD'),
        }}
        filters={[
          {
            name: "date",
            label: "Día",
            type: "date",
          },
        ]}
      />
      <h1 style={{ textAlign: 'center' }}>Ventas del día por hora</h1>
      <BarChart
        yAxis={[]}
        xAxis={[{
          scaleType: 'band',
          data: Object.keys(day),
          tickPlacement: 'middle'

        }]}
        series={[
          {
            type: 'bar',
            data: Object.values(day)
          }
        ]}
        colors={['#1976d2']}
        grid={{ horizontal: true }}
        // borderRadius={10}
        // barLabel="value"
        // width={500}
        height={300}
      />
      <h1 style={{ textAlign: 'center' }}>Ventas del día por operadora</h1>
      <PieChart
        series={[
          {
            data: Object.entries(operators).map(([operator, total]) => ({ id: operator, value: total, label: operator })),
            arcLabel: (params) => params.label ?? '',
          },
        ]}
        // width={400}
        height={400}
      />
      <h1 style={{ textAlign: 'center' }}>Ventas del día por estatus</h1>
      <PieChart
        series={[
          {
            data: Object.entries(states).map(([operator, total]) => ({ id: operator, value: total, label: operator })),
            arcLabel: (params) => params.label ?? '',
          },
        ]}
        // width={400}
        height={400}
      />
    </>
  )
}

export default Graficas