import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { setTitle } from 'redux/slices/uiSlice';
import { useDispatch } from 'react-redux';
import { CustomTab } from 'components';

const ErrorCodesTabScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setTitle('Homologación Errores Proveedor'));
    if(location.pathname === '/admin/proveedores/errorcodes')
      navigate('homologados');
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <CustomTab
        panels={[
          {
            name: "Homologados",
            route: '/admin/proveedores/errorcodes/homologados',
            onChange: () => navigate('homologados'),
            content: <Outlet />
          },
          {
            name: "Recibidos",
            route: '/admin/proveedores/errorcodes/recibidos',
            onChange: () => navigate('recibidos'),
            content: <Outlet />
          },
        ].filter(Boolean)}
      />
    </div>
  );
}

export default ErrorCodesTabScreen